import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
//Config
import Config from "../Config";
//context
import { store } from "../context/store";
import { BuildingPart } from "./client";
interface IChatState {
	connection: HubConnection | null;
}

const ChatHub = () => {
	const [state, setState] = useState<IChatState>({
		connection: null,
	});
	const chatHubUrl: string = Config.ChatHubUrl as string;

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(chatHubUrl)
			.withAutomaticReconnect()
			.build();
		setState({ connection: newConnection });
	}, []);

	useEffect(() => {
		if (state.connection) {
			state.connection
				.start()
				.then((result) => {
					console.log("Connected!");
					try {
						state.connection!.on(
							"UpdateAnchorApp",
							async (message: string) => {
								if (message) {
									store.dispatch({
										type: "SET_MODEL_URL",
										payload: message,
									});
								}
							}
						);
						state.connection!.on(
							"ResetAnchorDashboard",
							async (message: string) => {
								if (message) {
									let emptyBuildingPart = new BuildingPart();
									store.dispatch({
										type: "SET_BUILDINGPART",
										payload: emptyBuildingPart,
									});
								}
							}
						);
						state.connection!.on(
							"ReceiveBuildingPart",
							(message: string) => {
								if (message) {
									let buildingPart: any = JSON.parse(message);
									let finalBuildingPart = new BuildingPart();
									finalBuildingPart.applicationId =
										buildingPart.applicationId;
									finalBuildingPart.correctlyInstalled =
										buildingPart.CorrectlyInstalled;
									finalBuildingPart.id = buildingPart.id;
									finalBuildingPart.itemPrice =
										buildingPart.ItemPrice;
									finalBuildingPart.level =
										buildingPart.Level;
									finalBuildingPart.manufacturer =
										buildingPart.Manufacturer;
									finalBuildingPart.mark = buildingPart.Mark;
									finalBuildingPart.medium =
										buildingPart.Medium;
									finalBuildingPart.model =
										buildingPart.Model;
									finalBuildingPart.mountingTime =
										buildingPart.MountingTime;
									finalBuildingPart.name = buildingPart.Name;
									finalBuildingPart.physicalId =
										buildingPart.PhysicalId;
									finalBuildingPart.revitId =
										buildingPart.RevitId;
									finalBuildingPart.room = buildingPart.Room;
									finalBuildingPart.system =
										buildingPart.System;
									finalBuildingPart.totalChildrenCount =
										buildingPart.totalChildrenCount;
									finalBuildingPart.typeComments =
										buildingPart.TypeComments;
									finalBuildingPart.url = buildingPart.URL;
									store.dispatch({
										type: "SET_BUILDINGPART",
										payload: finalBuildingPart,
									});
								}
							}
						);
					} catch (error: any) {
						console.log(error.message);
					}
				})
				.catch((error: any) =>
					console.log("Connection failed: ", error)
				);
		}
	});

	return null;
};
export default ChatHub;
