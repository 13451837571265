//Service
import { BuildingPart } from "../services/client";

export interface IReducerState {
	buildingPart: BuildingPart | null;
	modelUrl: string;
}

const initialState = {
	buildingPart: null,
	modelUrl: "",
};

type SetBuildingPart = { type: "SET_BUILDINGPART"; payload: BuildingPart };
type SetModelUrl = { type: "SET_MODEL_URL"; payload: string };
type Action = SetBuildingPart | SetModelUrl;

export const reducer = (
	state: IReducerState = initialState,
	action: Action
) => {
	switch (action.type) {
		case "SET_BUILDINGPART": {
			return {
				buildingPart: action.payload,
				modelUrl: state.modelUrl,
			};
		}
		case "SET_MODEL_URL": {
			return {
				buildingPart: state.buildingPart,
				modelUrl: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};
