//Components
import ProductPropertiesPanel from "./components/product_properties/ProductPropertiesPanel";
import SpeckleViewer from "./components/speckle/SpeckleViewer";
import ChatHub from "./services/chatHub";
//Styling
import "./App.css";
//Context
import { Provider } from "react-redux";
import { store } from "./context/store";
//Config
import Config from "./Config";

function App() {
	const speckleUrl: string = Config.SpeckleUrl as string;
	return (
		<Provider store={store}>
			<ChatHub />
			<div className="App">
				<div style={{ float: "left" }}>
					<SpeckleViewer speckleUrl={speckleUrl} />
				</div>
				<div
					style={{
						float: "left",
						width: "45vw",
						height: "80vh",
						marginTop: "5vh",
						paddingLeft: "2.5vw",
					}}
				>
					<ProductPropertiesPanel />
				</div>
			</div>
		</Provider>
	);
}

export default App;
