//React
import { useEffect, useState } from "react";
//Speckle
import { Viewer } from "@speckle/viewer";
//context
import { useSelector } from "react-redux";
import { IReducerState } from "../../context/reducer";
//config
import Config from "../../Config";

interface IViewerProps {
	speckleUrl: string;
}

interface IViewerState {
	viewer: any;
	prevModelUrl: string;
}

const SpeckleViewer = (props: IViewerProps) => {
	const [state, setState] = useState<IViewerState>({
		viewer: null,
		prevModelUrl: "",
	});
	const modelUrl: any = useSelector<IReducerState>(
		(state: IReducerState) => state.modelUrl
	);
	const zoomFactor: number = Number(Config.zoomFactor as string);
	const objectToZoomId: string = Config.ObjectToZoomId as string;

	const zoomToObjectId = (id: string) => {
		let obj = state.viewer.sceneManager.allObjects.find(
			(o: { userData: { applicationId: string } }) =>
				o.userData.applicationId === id
		);
		if (obj) {
			state.viewer.interactions.zoomToObject(obj, zoomFactor, false);
		} else {
			console.warn(`No object with id of ${id} found.`);
		}
	};

	const adjustViewPoint = () => {
		zoomToObjectId(objectToZoomId);
		state.viewer.interactions.rotateTo("front");
		state.viewer.cameraHandler.controls.dollyToCursor = true;
		state.viewer.needsRender = true;
	};

	useEffect(() => {
		const rendererDiv = document.getElementById("renderer");
		if (!rendererDiv) {
			return;
		}
		if (!state.viewer) {
			const viewer = new Viewer({
				container: rendererDiv,
				showStats: false,
			});
			setState({ viewer: viewer, prevModelUrl: state.prevModelUrl });
			return;
		}
		state.viewer.loadObject(props.speckleUrl).then(async () => {
			zoomToObjectId(objectToZoomId);
			state.viewer.interactions.rotateTo("front", false);
			state.viewer.cameraHandler.controls.dollyToCursor = true;
			state.viewer.needsRender = true;
		});
	}, [state.viewer]);

	const loadNewModel = async () => {
		await state.viewer.unloadAll();
		await state.viewer.loadObject(modelUrl);
		adjustViewPoint();
		await state.viewer.unloadObject(state.prevModelUrl);
	};

	if (modelUrl !== "" && state.viewer && modelUrl !== state.prevModelUrl) {
		loadNewModel();
		setState({ viewer: state.viewer, prevModelUrl: modelUrl });
	}

	return (
		<div>
			<div style={{ width: "50vw", height: "100vh" }} id="renderer" />
		</div>
	);
};
export default SpeckleViewer;
