//MUI
import {
	Card,
	CardContent,
	CardHeader,
	List,
	ListItem,
	ListItemText,
	TextField,
} from "@mui/material";
//Context
import { useSelector } from "react-redux";
import { IReducerState } from "../../context/reducer";

const ProductPropertiesPanel = () => {
	const buildingPart: any = useSelector<IReducerState>(
		(state: IReducerState) => state.buildingPart
	);
	const inputStyle = {
		fontSize: 14,
		height: "2vh",
		width: "30vw",
		padding: "0",
		paddingTop: "1vh",
		fontWeight: "bold",
		paddingLeft: "1vw",
	};
	const listItemStyle = {
		borderLeft: "solid #349ceb",
		borderBottom: "solid gray 1px",
		bgcolor: "#f2f7ff",
		height: "3vh",
		paddingRight: "0",
		paddingBottom: "0",
		paddingTop: "1px",
	};
	const correctlyInstalled = () => {
		let correctlyInstalledText = "";
		if (buildingPart?.correctlyInstalled === undefined) {
			return correctlyInstalledText;
		}
		buildingPart?.correctlyInstalled
			? (correctlyInstalledText = "yes")
			: (correctlyInstalledText = "no");
		return correctlyInstalledText;
	};

	return (
		<Card variant={"outlined"}>
			<CardHeader
				title="Building Part Properties"
				sx={{ bgcolor: "#f2f7ff" }}
			/>
			<CardContent>
				<List dense={true}>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Name" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={buildingPart?.name ? buildingPart.name : ""}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="RevitId" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.revitId
									? buildingPart.revitId
									: ""
							}
							style={{ paddingLeft: "1px" }}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="PhysicalId" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.physicalId
									? buildingPart.physicalId
									: ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Manufacturer" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.manufacturer
									? buildingPart.manufacturer
									: ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="TypeComments" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.typeComments
									? buildingPart.typeComments
									: ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="URL" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={buildingPart?.url ? buildingPart.url : ""}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Mark" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={buildingPart?.mark ? buildingPart.mark : ""}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="ItemPrice" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.itemPrice
									? buildingPart.itemPrice
									: ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Model" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.model ? buildingPart.model : ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Level" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.level ? buildingPart.level : ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Room" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={buildingPart?.room ? buildingPart.room : ""}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="System" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.system ? buildingPart.system : ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="Medium" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.medium ? buildingPart.medium : ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="MountingTime" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={
								buildingPart?.mountingTime
									? buildingPart.mountingTime
									: ""
							}
						/>
					</ListItem>
					<ListItem sx={listItemStyle}>
						<ListItemText primary="CorrectlyInstalled" />
						<TextField
							variant={"filled"}
							inputProps={{
								style: inputStyle,
							}}
							value={correctlyInstalled()}
						/>
					</ListItem>
				</List>
			</CardContent>
		</Card>
	);
};
export default ProductPropertiesPanel;
